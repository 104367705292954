@import url("https://db.onlinewebfonts.com/c/7defc091a129b48948f730474b109d8f?family=Gilmer+Bold");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@font-face {
  font-family: "Gilmer Bold";
  src: url("https://db.onlinewebfonts.com/t/7defc091a129b48948f730474b109d8f.eot");
  src: url("https://db.onlinewebfonts.com/t/7defc091a129b48948f730474b109d8f.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7defc091a129b48948f730474b109d8f.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/7defc091a129b48948f730474b109d8f.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/7defc091a129b48948f730474b109d8f.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/7defc091a129b48948f730474b109d8f.svg#Gilmer Bold")
      format("svg");
}
