.div-title {
    font-size: 36px;
    font-weight: 700;
    font-family: "Gilmer Bold";
    color: hsla(0, 0%, 12%, 1);
    padding: 0 0 3rem 0;

    & > span {
        color: hsla(40, 97%, 55%, 1);
    }
}

@media screen and (max-width: 580px) and (min-width: 260px) {
    .div-title {
        font-size: 26px;
        line-height: 1.4;
        text-align: center;
    }

    .div-title > span {
        font-size: 26px;
    }
}