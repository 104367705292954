@use "../../styles/variables.scss" as *;
.header {
  background-color: $color__white_shade_1;
  position: fixed;
  height: 6rem;
  display: flex;
  align-items: center;
  // filter: drop-shadow(2px 4px 6px hsla(0, 0%, 0%, 0.08));
  width: 100%;
  top: 0;
  z-index: 5;
  &__inner-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 2.3rem;
  }
  &__logo-cont {
    svg {
      cursor: pointer;
      width: 14rem;
      height: 4.2rem;
    }
  }

  &__details-cont {
    display: flex;
    align-items: center;

    @media screen and (max-width: 37em) {
      label,
      button {
        display: none;
      }
    }

    label {
      font-size: calc($normal - 0.3vw);
      color: $color__black;
      cursor: pointer;
      font-weight: 900;

      a {
        color: $color__black;
        text-decoration: none;
      }
    }
    svg {
      cursor: pointer;
      width: 2.5rem;
      height: 2.5rem;
    }

    button {
      border-radius: 2rem;
      margin-inline: 2rem;
      background-color: $color__yellow;
      padding: 0.7rem 1.3rem;

      & > a {
        font-size: calc($normal - 0.6em);
        font-weight: 700;
        color: $color__white;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 580px) and (min-width: 260px) {
  .header {
    height: 6rem;
    width: 100%;
  }

  .header__inner-cont {
    padding: 0.3rem;
    width: 90%;
  }

  .header__details-cont {
    gap: 10px;
    & > button {
      border-radius: 2rem;
      margin-inline: 0rem;
      background-color: #fcb01a;
      padding: 0.7rem 0.4rem;
      font-size: 12px;

      & > p {
        font-size: 10px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
}

.showdow {
  filter: drop-shadow(2px 4px 6px hsla(0, 0%, 0%, 0.08));
}

.text-white {
  p {
    font-weight: 500;
    font-size: calc($normal - 0.3vw);
    font-family: "Manrope", sans-serif;
    color: $color__white;
  }
}

.log-out {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $color__white;
  font-size: calc($normal - 0.3vw);
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}
