@use "../../styles/variables.scss" as *;

.contact__section {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  overflow-y: hidden;
  // min-height: 100vh;
  // overflow: auto;
  // margin: 9rem 0 0 0;

  &--left {
    width: 30%;
    background: hsla(40, 97%, 55%, 1);
    position: relative;
    z-index: 5;

    &-side {
      position: absolute;
      right: -22rem;
      top: 15rem;

      & > h1 {
        font-size: 8.5rem;
        width: 150%;
        line-height: 1.1;
        color: #fff;

        & > span {
          color: hsla(40, 97%, 55%, 1);
        }
      }
    }
  }

  &--right {
    width: 70%;
    padding-top: 10rem;
    position: relative;
    padding: 13rem 7rem 7rem 7rem;

    &-form {
      position: absolute;
      width: 100%;
      top: 0%;
      left: 0;

      &-inputs {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 4rem;
      }
    }
  }
}

.contact__section--right-side {
  height: 100%;
  margin-top: 7rem;
}

.ant-col.ant-form-item-label {
  overflow: visible;
}

.contact-form-input {
  width: 45%;
  margin: 0 auto;
}

.contact-input {
  background: transparent;
  border-width: 0;
  border-color: transparent;
  border-bottom: 2px solid hsla(0, 0%, 0%, 0.25);
  border-radius: 0;
  // padding-left: 9rem;

  &:hover {
    border-color: transparent;
    background-color: transparent;
    border-bottom: 2px solid hsla(0, 0%, 0%, 0.25);
  }

  &:focus {
    border-color: transparent;
    box-shadow: none;
    outline: 0;
    border-bottom: 2px solid hsla(0, 0%, 0%, 0.25);
    background-color: transparent;
  }
}

form#ContactForm {
  padding: 30rem 1.5rem 1.5rem 1.5rem;
}

.ant-form-item.contact-form-input.query {
  width: 95%;
}

input#ContactForm_firstname {
  text-indent: 8rem;
}

input#ContactForm_lastname {
  text-indent: 8rem;
}

input#ContactForm_email {
  text-indent: 4.6rem;
}
input#ContactForm_designation {
  text-indent: 10rem;
}

textarea#ContactForm_query {
  text-indent: 4.5rem;
}

.contact-form-input > div > div > label {
  position: absolute !important;
  left: 80%;
  color: hsla(0, 0%, 27%, 1);
  font-weight: 500;
}

a.ant-dropdown-trigger.contact-input {
  width: 100%;
  display: flex;
  padding-left: 0;
  color: hsla(0, 0%, 27%, 1);
}

.domain-click {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
}

button.ant-btn.contact-form-submit-btn {
  border: 1px solid hsla(40, 97%, 55%, 1);
  border-radius: 25px;
  color: hsla(40, 97%, 55%, 1);
  font-weight: 700;
  font-family: "Manrope";
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px 0px hsla(0, 0%, 0%, 0.16);

  &:hover {
    color: hsla(40, 97%, 55%, 1) !important;
    border: 1px solid hsla(40, 97%, 55%, 1) !important;
    background: #ffffff !important;
  }

  &:focus-visible {
    outline: 4px solid transparent;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
  }
}

.form-button-submit {
  display: flex;
  justify-content: flex-end;
  width: 97%;
}

.contact {
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
}

.contact_left.contact {
  height: 100%;
  background: #fcb11d;
  height: fit-content;
  left: 0;
  z-index: -1;
}

.contact__white_logo {
  position: relative;
  z-index: 1;
  height: 42px;
  margin-top: 10px;
  margin-left: 5rem;
}

@media screen and (max-width: 768px) and (min-width: 581px) {
  .contact__section {
    &--left {
      &-side {
        position: absolute;
        right: -15rem;
        top: 15rem;

        & > h1 {
          font-size: 6rem;
          width: 150%;
          line-height: 1.1;
          color: #fff;

          & > span {
            color: hsla(40, 97%, 55%, 1);
          }
        }
      }
    }
  }

  form#ContactForm {
    padding: 24rem 1.5rem 1.5rem 1.5rem;
  }
}

@media screen and (max-width: 580px) and (min-width: 260px) {
  .contact {
    position: absolute;
    width: 100%;
    top: 0%;
    height: 5rem;
    background: #fcb11d;
  }

  .contact__section {
    flex-direction: column;
    padding-top: 12rem;

    &--left {
      display: none;
    }

    &--right {
      width: 100%;
      padding: 1rem;
    }
  }

  form#ContactForm {
    padding: 3rem 3.5rem 1.5rem 3.5rem;
  }

  .contact__section--right-form-inputs {
    flex-direction: column;
    gap: 2rem;
  }

  .contact-form-input {
    width: 100%;
    margin: 0 auto;
  }

  .contact-form-input > div > div > label {
    left: 0;
  }

  .contact__section--right-form-inputs {
    margin-bottom: 1rem;
  }

  .form-button-submit {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  textarea#ContactForm_query {
    margin-top: -7px;
  }

  button.ant-btn.contact-form-submit-btn {
    justify-content: center;
    width: 80vw;
    margin-top: 2rem;
  }

  .ant-form-item.contact-form-input.query {
    width: 100%;
  }
}

.ant-select-selector {
  border: none !important;
  background: transparent !important;
  border-width: 0;
  border-color: transparent;
  border-bottom: 2px solid hsla(0, 0%, 0%, 0.25) !important;
  border-radius: 0 !important;
}

.ant-select-arrow {
  color: hsla(40, 97%, 55%, 1) !important;
}

.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}

.pad-0 {
  padding: 0 !important;
}

.ant-form-item-required {
  &::before {
    content: "" !important;
  }
  &::after {
    content: "*  :" !important;
    margin-inline-start: 0.2rem !important;
    color: #575757 !important;
  }
}

.mobile-header-contact {
  background-color: $color__yellow;
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
  padding: 0.5rem 10% 0.5rem 0;
  z-index: 5;
  position: absolute;

  svg {
    width: 3.2rem;
    path {
      stroke: $color__white;
    }
  }
}

@media screen and (min-width: 580px) {
  .mobile-header-contact {
    display: none;
  }
}
