@use "../../styles/variables.scss" as *;

.events__section {
    margin-top: 9rem;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    text-align: center;

    &__img {
        max-width: 500px;
        width: 100%;
    }

    & > p {
        font-size: clamp(1.5rem, 2.5vw, 4rem); 
        font-weight: 500;
        font-family: "Manrope";
        line-height: 1.6;
        color: hsla(0, 0%, 27%, 1);
    }

    &__btn {
        border: 1px solid hsla(40, 97%, 55%, 1);
        border-radius: 25px;
        color: hsla(40, 97%, 55%, 1);
        box-shadow: 0px 4px 8px 0px hsla(0, 0%, 0%, 0.16);
        font-weight: 700;
        line-height: 1.4;
        font-family: "Manrope";
        display: flex;
        align-items: center;

        &:hover {
            color: hsla(40, 97%, 55%, 1) !important;
            border: 1px solid hsla(40, 97%, 55%, 1) !important;
            background: #ffffff !important;
        }
    
        &:focus-visible {
            outline: 4px solid transparent;
            outline-offset: 1px;
            transition: outline-offset 0s, outline 0s;
        }
    }
}

@media screen and (max-width: 580px) and (min-width: 260px) {
    
    .events__section > p {
        max-width: 300px;
    }

    .events__section__img {
        max-width: 300px;
        width: 100%;
    }

}