@use "../../styles/variables.scss" as *;

.home {
  top: 8rem;
  position: absolute;
  min-height: 90vh;
  height: 75rem;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  background-color: $color__white_shade_1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(47rem, 1fr));
  &__map-box {
    height: 80%;
    display: flex;
    section {
      @media screen and (min-width: 50em) {
        margin-left: auto;
      }
      @media screen and (max-width: 50em) {
        margin-inline: auto;
      }
      position: relative;
      margin-block: auto;
    }

    &__map-cont {
      height: 85vh;
      overflow: hidden;
      margin-block: auto;
      margin-top: -2rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__network-cont {
      width: 100%;
      position: absolute;
      top: 0%;
      bottom: 0%;
    }
  }

  &__desc-box {
    height: 90%;
    display: flex;
    position: relative;
    align-items: flex-start;
    @media screen and (max-width: 50em) {
      align-items: center;
    }
    justify-content: flex-start;
    flex-direction: column;

    &__desc-cont {
      display: flex;
      flex-direction: column;
      height: 70%;
      padding-top: 5rem;
      padding-left: 3rem;
      @media screen and (max-width: 57em) {
        align-self: center;
        width: fit-content;
        margin-block: 0rem;
        width: 100%;
        padding: 0;
      }
      justify-content: space-between;
      gap: 1rem;

      &-Powered__by {
        color: hsla(0, 0%, 54%, 1);
        font-weight: 900;
        line-height: 1.2;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: uppercase;
        margin-bottom: 2rem;

        &-img {
          width: 110px;
          margin-left: 2rem;
        }
      }
      h1 {
        font-size: clamp(1.5rem, 3.2vw, 4.1rem);
      }
      header {
        display: flex;
        @media screen and (max-width: 57em) {
          justify-content: flex-start;
        }
        h1 {
          text-wrap: nowrap;
          width: fit-content;
          span {
            display: block;
            position: relative;
            padding: 0.6rem 0.9rem;
            letter-spacing: 0.6px;
            width: fit-content;
            line-height: 0.8;

            @media screen and (max-width: 30em) {
              font-size: $medium;
            }
            font-size: 33px;
            font-weight: 600;
            border-radius: 0 0 0.6rem 0.6rem;
            &:first-of-type {
              border-radius: 0.6rem 0.6rem 0 0;
            }
            &:last-of-type {
              border-radius: 0rem 0.6rem 0.6rem 0.6rem;
            }
            background-color: #fcb01a1a;
          }
        }
        a {
          align-self: flex-end;
        }
        svg {
          // width: 6rem;
          cursor: pointer;
        }
      }

      main {
        margin-block: 2rem 5rem;
        & > p {
          font-size: 26px;
          @media screen and (max-width: 30em) {
            font-size: $medium;
          }
          line-height: 0.8;
          font-weight: 300;
          span {
            color: $color__yellow;
            &:first-of-type {
              margin-left: 1.4rem;
              font-weight: 600;
            }
          }
        }
      }

      footer {
        // position: absolute;
        top: 90%;
        @media screen and (min-width: 50em) {
          // top: 77%;
        }
        z-index: 1;
      }
    }
    &__icon-cont {
      margin-top: -22rem;
      svg {
        width: 100%;
        height: fit-content;
      }
    }
  }

  &__get-page {
    padding-top: 6rem;
    align-items: center;
    display: flex;
    margin-block: 3.3rem;
    min-height: 90vh;
    &__arrow-left {
      margin-left: 2rem;
      cursor: pointer;
    }

    &__arrow-right {
      position: absolute;
      right: 25px;
      z-index: 1;
      top: 50%;
    }

    &__inner {
      margin-bottom: 2rem;
      @media (min-width: 60em) {
        margin-right: 6rem;
      }
      display: grid;
      place-items: center;
      grid-template-columns: repeat(auto-fit, minmax(42rem, 1fr));
      @media (max-width: 30em) {
        grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
      }
    }

    &__icons-box {
      display: flex;
      section {
        margin: auto;
      }
      header {
        h1 {
          line-height: 1.1em;
          font-size: calc($extra__large - 0.5vw);
        }
      }

      footer {
        display: flex;
        flex-wrap: wrap;
        div {
          display: flex;
          align-items: center;
          margin-block: 1.5vw;
          flex: 1 1 42%;

          svg {
            width: 15%;
          }
          label {
            font-size: calc($normal - 0.2vw);
            margin-left: 2rem;
            font-weight: 700;
          }
        }
      }
      header,
      main,
      footer {
        margin-block: 2rem;
      }
      p {
        line-height: 1.5em;
        font-weight: 300;
        font-size: calc($normal + 0.1vw);
      }
      &__icons-cont {
        display: flex;
        width: 100%;
      }
    }

    &__svg-bg-cont {
      width: 65vw;
      position: fixed;
      transform: rotate(-5deg);
      bottom: 0%;
      right: 0%;

      svg {
        width: 100%;
        height: fit-content;
      }
    }

    &__desc-box {
      z-index: 1;
      &__top-sec {
        display: grid;
        @media screen and (max-width: 40em) {
          grid-template-columns: 1fr;
        }
        grid-template-columns: 56% 42%;
        gap: 2%;
        margin-block: 2rem;
      }

      &__bottom-sec {
        width: 100%;
      }
    }
  }
}

.bigger_cursor {
  font-size: calc($large + 1vw);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 100;
}

@media screen and (max-width: 580px) and (min-width: 260px) {
  section.home.fade-in-from-bottom.show::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: transparent;
    width: 100%;
    display: none;
  }

  .home__desc-box__desc-cont header h1 span {
    font-size: 20px;
  }

  .home__get-page__arrow-right {
    top: 50%;
  }

  .home {
    bottom: 0;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .home__map-box {
    height: 90%;
  }

  .home__desc-box__icon-cont {
      position: absolute;
      bottom: -8%;
      right: 0%;
  }

  .home__map-box__map-cont {
    width: 100%;
  }

  .home__map-box__network-cont {
    width: 100%;
  }

}
