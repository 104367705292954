@use "../../../styles/variables.scss" as *;

.navigation {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 23vw;
  height: 100vh;
  z-index: 3;
  background-color: $color__white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  li {
    list-style: none;
  }

  header {
    section {
      margin-top: 2.3rem;
      display: flex;
      align-items: center;
      column-gap: 1rem;

      label {
        font-weight: 600;
        font-size: $medium;
        cursor: default;
        @media screen and (max-width: 580px) {
          display: block;
        }
        @media screen and (min-width: 580px) {
          display: none;
        }
      }
      svg {
        width: 3.5rem;
      }
    }
  }

  main {
    margin-block: auto;
    background-color: $color__white;
    ul {
      display: flex;
      flex-direction: column;
      li {
        color: $color__black;
        width: fit-content;
        cursor: pointer;
        font-weight: 700;
        padding: 2rem 0;
        line-height: 1.3em;
        font-size: $medium;
        position: relative;

        &:not(:nth-child(7)):not(:nth-child(8))::after {
          content: "";
          position: absolute;
          left: 0;
          top: 100%;
          width: 50px;
          height: 1px;
          background: lightgray;
        }

        a {
          text-decoration: none;
          color: $color__black;
        }
      }
    }
  }

  footer {
    background-color: $color__yellow;
    // padding: 1.2rem;
    display: flex;
    flex-direction: column;
    // padding-block: 1.1rem;
    section {
      ul {
        display: flex;
        margin-block: 1.1rem;
        justify-content: space-between;
        align-items: center;
        li {
          color: $color__white;
          line-height: 1.3em;
          margin-block: 1rem;
          // background-color: red;
          text-align: start;
          height: fit-content;
          padding: 0 1.3vw;
          font-size: 8px;
          &:not(:last-of-type) {
            border-right: 1px solid $color__white;
          }
        }
      }

      label {
        color: $color__white;
        font-size: calc($small - 0.3vw);
        font-weight: 400;
        line-height: 1.3em;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 7px;

        a {
          margin-inline: 0.1rem;
          text-decoration: underline;
          padding-block: 0.1rem;
          color: inherit;
          font-weight: 700;
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) and (min-width: 779px) {
  .navigation {
    width: 28vw;
  }
}

@media screen and (max-width: 780px) and (min-width: 579px) {
  .navigation {
    width: 45vw;
  }
}

@media screen and (max-width: 580px) and (min-width: 260px) {

  .navigation {
    width: 100vw;
  }

  .navigation header section {
    height: 30px;
  }

  .navigation header section svg {
      position: absolute;
      left: 0;
  }

  .navigation footer section ul {
    justify-content: center;
  }

  .navigation footer section label a {
    font-size: 12px;
  }
}
