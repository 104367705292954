// colors
$color__yellow: #fcb01a;
$color__black: #1e1e1e;
$color__white: #ffffff;
$color__black_shade_2: #7c7b7b;
$color__white_shade_1: #fbfbfb;
$color__black_shade_1: #444444;
$color__black_shade_2_light: #7c7b7b4a;

// sizes
$extra__large: clamp(3.1rem, 4.2vw, 6.9rem);
$large: clamp(3rem, 3.2vw, 4.1rem);
$medium: clamp(2.4rem, 2.5vw, 2.5rem);
$normal: clamp(1.4rem, 1.2vw, 1.7rem);
$small: clamp(1.3rem, 1.3vw, 1.3rem);

$paragraph__font: "";
$heading__font: "";
