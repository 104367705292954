@use "../../styles/variables.scss" as *;

.icon-cont {
  display: flex;
  flex: 1 1 42%;
  &:not(:last-of-type) {
    margin-right: 3.2rem;
  }
  flex-direction: column;
  section {
    display: flex;
    align-items: center;
    h3 {
      color: $color__yellow;
      letter-spacing: 0.2em;
      font-weight: 600;
      font-size: $medium;
      line-height: 1.3em;
    }

    svg {
      margin-right: 2rem;
    }
  }
  blockquote {
    margin-block: 1.4rem;
    text-wrap: nowrap;
    line-height: 1.3em;
    font-size: $small;
    color: $color__black_shade_1;
  }
}
