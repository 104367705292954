@use "../styles/variables.scss" as *;
.container {
  &__outer {
    max-width: 150rem;
    margin-inline: auto;
  }
}

.wid-90 {
  width: 90%;
}

.wid-95 {
  width: 95%;
}

.wid-75 {
  width: 75%;
}

.wid-80 {
  width: 80%;
}

.m-x-auto {
  margin-inline: auto;
}
.m-y-auto {
  margin-block: auto;
}

.color-black {
  color: $color__black;
}

.m-auto {
  margin: auto;
}

.color-yellow {
  color: $color__yellow;
}

.blink {
  animation: blink 1.4s infinite;
}
@keyframes blink {
  to {
    opacity: 0;
  }
}

.m-y-block-large {
  margin-block: $large;
}

.display-in-blck {
  display: inline-block;
}

.arrow-left-box {
  position: absolute;
}

.arrow-right-box {
  position: absolute;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}

.d-flex {
  display: flex;
}

.pos-abs-y-center {
  position: fixed;
  top: 55%;
  transform: translateY(-55%);
}

.fade-in-from-bottom {
  opacity: 0; /* Initially hidden */
  transform: translateY(60%); /* Initially moved below the viewport */
  transition: opacity 1s ease, transform 1s ease; /* Transition for smooth animation */ /* Transition for smooth animation */

  &.show {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
  }
}

.flex-start {
  justify-content: start !important;
}

.heading-flex {
  display: flex;
  justify-content: center;
  h1 {
    font-size: $large !important;
  }
  align-items: center;
  column-gap: $normal;
}

.text-center {
  text-align: center;
}

.arrow__left_and_right_size {
  cursor: pointer;
  z-index: 3;

  svg {
    width: 3.3rem !important;
  }
}

.arrow__common-left {
  left: 2% !important;
}

.arrow__common-right {
  right: 2% !important;
}
