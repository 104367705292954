@use "../../styles/variables.scss" as *;

.component {
  &__button {
    cursor: pointer;
    border: none;
    outline: none;
  }

  &__img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
