@use "../../styles/variables.scss" as *;
.cutom-btn-yellow {
  background-color: $color__yellow;
  border-radius: 2.4rem;
  box-shadow: 0px 4px 8px 0px hsla(0, 0%, 0%, 0.16);
  
  a {
    padding: 1rem 3.3rem;
    border-radius: 2.4rem;
    div {
      display: flex;
      align-items: center;
      p {
        font-size: $small;
        // font-size: 0.5rem;
        color: $color__white;
        line-height: 1.5em;
      }
      svg {
        margin-left: 1.5rem;
        margin-top: 0.2rem;
        width: $small;
        height: fit-content;
      }
    }
  }
}
