*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  @media (max-width: 100em) {
    font-size: 62.5%; // approx 10px
  }
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 100%;
  line-height: 1.3em;
  background-color: #fbfbfb;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

button,
a {
  cursor: pointer;
  text-decoration: none;
}

.App {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilmer Bold";
  box-sizing: border-box;
}
