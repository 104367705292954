@use "../../styles/variables.scss" as *;

.aatral {
  margin-block: 2.3rem;
  padding-top: 9rem;
  min-height: 100vh;
  overflow: hidden;
  // overflow-x: hidden;
  // overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  &__top-sec {
    display: flex;
    width: 90vw;
    &__desc-box {
      display: flex;
      flex-direction: column;
      flex: 1 1 55%;

      main {
        margin-block: 1.4rem;
        p {
          font-size: $normal;
          font-weight: 300;
          color: $color__black_shade_1;
          line-height: 1.5em;
        }
      }

      footer {
        display: flex;
        flex-wrap: wrap;
        margin-block: 3.3rem;
        row-gap: 5vw;
      }

      &__icon-cont {
        display: flex;
        // min-width: max-content;
        flex: 1 1 42%;
        &:not(:last-of-type) {
          margin-right: 3.2rem;
        }
        flex-direction: column;
        section {
          display: flex;
          align-items: center;
          h3 {
            color: $color__yellow;
            letter-spacing: 0.2em;
            font-weight: 600;
            font-size: $medium;
            line-height: 1.3em;
          }

          svg {
            margin-right: 2rem;
          }
        }
        blockquote {
          margin-block: 1.4rem;
          text-wrap: nowrap;
          line-height: 1.3em;
          font-size: $small;
          color: $color__black_shade_1;
        }
      }
    }
    &__img-box {
      flex: 0 1 45%;
      margin-top: -10%;
      svg {
        width: 41vw;
        max-width: fit-content;
        height: 100%;
      }
    }
  }
  &__name-sec {
    header {
      margin-block: 1rem 2rem;
      h1 {
        display: flex;
        column-gap: 2rem;
        line-height: 1.3em;
        font-size: $extra__large;
      }
    }

    main {
      margin-top: 1rem;

      p {
        color: $color__black_shade_1;
        text-align: justify;
        line-height: 1.5em;
        font-size: $normal;
        font-weight: 300;
      }
    }
  }

  &__vision-sec {
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: relative;
    margin-block: 3rem 3.3rem;
    &__main-cont {
      display: flex;
      align-items: center;

      &__desc-box {
        flex: 1 0 27vw;
        margin-right: -10%;
        margin-bottom: 11vw;
        h1 {
          display: flex;
          justify-content: center;
          margin-block: 2.3rem;
          column-gap: 2rem;
          text-align: center;
          font-size: $extra__large;
          line-height: 1.3em;
        }

        P {
          font-size: $normal;
          width: 80%;
          margin-inline: auto;
          line-height: 1.5em;
          text-align: center;
          font-weight: 300;
          color: $color__black_shade_1;
        }
      }

      &__icon-box-mike {
        flex: 0 1 27vw;
        svg {
          width: 100%;
          height: fit-content;
        }
      }
      &__icon-box-cup {
        flex: 0 1 43vw;
        svg {
          width: 100%;
          height: fit-content;
        }
      }
    }

    footer {
      position: absolute;
      bottom: 0%;
      height: 23.5rem;
      width: 100%;
      background: linear-gradient(181deg, transparent 0%, white 40%, white 0%);

      svg {
        width: 100vw;
      }
    }
  }

  &__committe-sec {
    margin-block: 0 3rem;

    &__list-box {
      display: flex;
      gap: 4.5rem;
      flex-wrap: wrap;
      justify-content: center;
      align-items: baseline;
      width: 90%;
      margin: 0 auto;
    }
    &__item-box {
      flex-basis: 13rem;
      &__img-cont {
        margin-block: 2rem;
        width: 160px;
        img {
          border-radius: 50%;
        }
        svg {
          width: 15rem;
        }
      }
      &__desc-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        label {
          color: $color__black;
          font-size: 13px;
          text-align: center;
          line-height: 1.3em;
          margin-bottom: 0rem;
          font-weight: 700;
        }

        blockquote {
          text-align: center;
          line-height: 1.5em;
          color: $color__black_shade_1;
          font-size: 11.5px;
          line-height: 1.6;
          text-align: center;
        }
      }
    }
  }

  &__join-sec {
    position: relative;
    margin-block: 5rem 0;
    height: 30rem;

    &__content-box {
      position: absolute;
      top: 24%;
      left: 50%; /* Move the element to the horizontal center of its containing block */
      transform: translateX(-50%);
      display: flex;
      gap: 3.3rem;
      flex-direction: column;
      align-items: center;
      label {
        color: $color__black;
        font-size: 14px;
        line-height: 1.5em;
        font-weight: 700;
      }

      button {
        background-color: $color__yellow;
        border-radius: 2.4rem;

        div {
          display: flex;
          margin: 1rem 3.3rem;
          align-items: center;
          p {
            font-size: calc($normal - 0.3vw);
            color: $color__white;
            line-height: 1.5em;
          }
          svg {
            margin-left: 1.5rem;
            margin-top: 0.2rem;
            width: $normal;
            height: fit-content;
          }
        }
      }
    }
    svg {
      width: 99vw;
      height: 100%;
    }
  }

  &__btm-sv {
    position: absolute;
    bottom: -10%;
  }
}

.heading {
  display: flex;
  justify-content: center;
  margin-block: 2.3rem;
  column-gap: 2rem;
  text-align: center;
  font-size: $extra__large;
  line-height: 1.3em;
}

.streeing-ali-adjust {
  margin-top: -9rem;
  z-index: 1;
}

section.aatral__committe-sec.streeing-ali-adjust.container__outer {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

section.aatral__committe-sec.container__outer {
  margin: 0 auto;
  width: 100%;
}

.about___rise {
  display: flex;
  justify-content: center;
  max-width: 80%;
  border: 1px solid hsla(40, 97%, 55%, 1);
  position: relative;
  z-index: 999;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 4px 8px 0px hsla(0, 0%, 0%, 0.08);
  border-radius: 5px;
  height: 17rem;
  flex-wrap: wrap;
  margin-top: -7rem;
  z-index: 0;
}

.about__rise {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  &--left {
    padding: 2rem;
  }
}

.about__rise--right {
  & > p {
    font-size: 15px;
    line-height: 1.6;
  }

  & > a {
    text-decoration: none;
    font-weight: 700;
    font-size: 13px;
    color: hsl(40, 97%, 55%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // position: absolute;
    // right: 40px;
    // bottom: 10px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 769px) {
  .aatral__join-sec svg {
    width: 100%;
    height: revert-layer;
  }
}

@media screen and (max-width: 768px) and (min-width: 581px) {
  .aatral__top-sec {
    flex-direction: column;
  }

  .aatral__top-sec__img-box {
    margin-top: 0;
  }

  .aatral__top-sec__img-box svg {
    width: 90%;
  }

  .aatral__vision-sec__main-cont__icon-box-mike,
  .aatral__vision-sec__main-cont__icon-box-cup,
  .aatral__vision-sec footer {
    display: none;
  }

  .aatral__vision-sec__main-cont__desc-box {
    margin-right: 0;
  }

  .about___rise {
    height: 100%;
  }

  .aatral__join-sec svg {
    height: revert-layer;
  }

  .aatral__name-sec main p,
  .aatral__vision-sec__main-cont__desc-box P,
  .aatral__top-sec__desc-box main p {
    font-size: 15px;
  }
}

@media screen and (max-width: 580px) and (min-width: 260px) {
  .aatral {
    padding-top: 6rem;
  }

  section.wid-80.m-x-auto.aatral__inner {
    width: 85%;
  }

  .aatral__top-sec {
    flex-direction: column;
  }

  .heading-flex {
    justify-content: center !important;
  }

  .aatral__name-sec main p,
  .aatral__vision-sec__main-cont__desc-box P,
  .aatral__top-sec__desc-box main p {
    font-size: 1.4rem;
    width: 100%;
  }

  .aatral__vision-sec__main-cont__desc-box {
    margin-right: 0;
  }

  .aatral__vision-sec__main-cont__desc-box P {
    width: 90%;
  }

  .aatral__top-sec__img-box svg {
    width: 100%;
  }

  .aatral__vision-sec__main-cont__icon-box-cup,
  .aatral__vision-sec__main-cont__icon-box-mike,
  .aatral__vision-sec footer {
    display: none;
  }

  .aatral__vision-sec__main-cont__desc-box {
    width: 90%;
    margin: 0 auto;
  }

  .about___rise {
    margin-top: 0;
    height: 100%;
    max-width: 90%;
  }

  .about__rise {
    flex-direction: column;
  }

  .aatral__join-sec svg {
    height: revert-layer;
  }

  .aatral__join-sec__content-box button div {
    margin: 0.6rem 1.3rem;
    font-size: 1.2rem;
  }

  .aatral__join-sec__content-box button {
    width: 120%;
    display: flex;
    justify-content: center;
  }

  .aatral__join-sec__content-box label {
    text-align: center;
  }

  .icon-cont {
    align-items: center;
  }
}
