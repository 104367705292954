@use "../../styles/variables.scss" as *;

.mail__sucess-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 580px) and (min-width: 260px) {
    justify-content: center;
    width: 90%;
    margin-inline: auto;
  }
  height: 100vh;

  &__desc-cont {
    display: flex;
    flex-direction: column;
    label {
      font-size: calc($medium - 0.3vw);
      font-weight: 400;
      text-align: center;
      line-height: 1.5em;
      color: $color__black_shade_1;
    }

    button {
      margin-block: 1.5rem;
      background-color: transparent !important;
      display: flex;
      justify-content: center;
      a {
        cursor: pointer;
        display: flex;
        font-size: calc($normal - 0.1vw);
        align-items: center;
        gap: 0.7rem;
        color: $color__yellow;
        svg {
          width: calc($normal - 0.1vw);
        }
      }
    }
  }
  &__icon-cont {
    align-self: flex-end;
    @media screen and (min-width: 580px) {
      margin-top: 5rem;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    svg {
      width: 42vw;
      height: 33vh;
    }
  }
}
