.social-icon {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 6;
  top: 60%;
  left: 2%;

  &__item-box {
    width: fit-content;
    height: fit-content;
    &:not(:last-of-type) {
      margin-bottom: 2.3rem;
    }
  }
}

@media screen and (max-width: 580px) and (min-width: 260px) {
  .social-icon {
    top: 80%;
    z-index: 4;
  }
}

.white-icon {
  @media screen and (min-width: 580px) {
    .anticon svg {
      path {
        fill: white !important;
      }
    }
  }
}
