@use "../../styles/variables.scss" as *;

.members__section {
  background: #fff;
  margin-top: 6rem;

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    background: rgba(252, 176, 26, 0.1019607843);

    &__title {
      color: #000;
      font-size: 4rem;
      font-weight: 700;
      margin-bottom: 0;

      &--span {
        color: rgb(252, 177, 27);
      }
    }
  }

  &__second__section {
    padding: 6rem 0;
    width: 100%;
    background-image: url(../../assets/Images/members/members_bg.png);
    background-position: -40% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    &__members {
      display: flex;
      flex-wrap: wrap;
      gap: 2.5rem;
      width: 90%;
      margin: 0 auto;
      justify-content: center;

      &--user {
        position: relative;
        min-width: 230px;
        max-width: 240px;
        height: 240px;

        &:hover {
          cursor: pointer;
        }

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &-details {
          position: absolute;
          width: 90%;
          transform: translate(5%, -150%);
          background: #fff;
          padding: 0.7rem;

          &-name {
            font-size: 12px;
            margin-bottom: 0px;
            font-weight: 500;
            letter-spacing: 0.3px;
            line-height: 1.5;
            font-family: "Manrope", sans-serif;
          }

          &-company {
            font-size: 12.39px;
            margin-bottom: 0px;
            font-weight: 300;
            letter-spacing: 0.3px;
            line-height: 1.4;
            font-family: "Manrope", sans-serif;
          }
        }
      }
    }
  }
}

.member_modal_whole {
  transform: translate(0px, 100px);
}

.member__modal {
  display: flex;
  flex: 1 1 0;
  column-gap: 3%;

  &__profile {
    flex-basis: 27%;
    padding-top: 1.5rem;
    padding-left: 1.5rem;

    &--picture {
      width: 240px;
      height: 240px;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    &--details {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      width: 240px;

      &-names > p:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 1.5;
        font-family: "Manrope", sans-serif;
        color: hsla(0, 0%, 12%, 1);
      }

      &-names > p:nth-child(2) {
        color: hsla(0, 0%, 27%, 1);
        font-size: 13.26px;
        font-weight: 300;
        margin-bottom: 0;
        line-height: 18.12px;
      }

      &-social {
        display: flex;
        gap: 8px;
        line-height: 2.5;

        &-link:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__description {
    flex-basis: 70%;
    padding: 1rem 1rem 0 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    &--texts {
      // height: 60vh;
      overflow-y: scroll;
      padding-right: 1rem;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }
      & ::-webkit-scrollbar-thumb {
        background: rgba(211, 211, 211, 0.326);
        border-radius: 10px;
      }
    }

    &--texts > p {
      line-height: 1.6;
      font-family: "Manrope", sans-serif;
      font-weight: 300;
      font-size: 16px;
      text-align: justify;
    }

    &--logo {
      width: 300px;
      height: 50px;
      display: flex;
      justify-content: flex-end;

      & > a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & > img {
          height: 70%;
          width: auto;
        }
      }
    }
  }
}

.ant-modal .ant-modal-content {
  border-radius: 25px !important;
}

@media screen and (max-width: 580px) and (min-width: 260px) {

  .members__section__second__section__members--user {
    min-width: 270px;
  }

  .members__section__block__title {
    font-size: 3rem;
  }

  .members__section__block__title--span {
    margin-left: 10px;
  }

  .member__modal__description--logo > a {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .member__modal__description--logo > a {
    width: 100%;
    overflow: hidden;
  }

  .member__modal {
    display: flex;
    height: 100vh;
    flex-direction: column;

    &__profile,
    &__description {
      width: 100%;
    }

    &__description {
      padding: 1rem 0;
      gap: 2rem;

      &--texts {
        height: 40vh;
        padding-right: 0;
      }

      &--logo {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
      }

      &--logo > a > img {
        height: 200px;
        width: 100px;
        object-fit: contain;
      }
    }
  }

  .ant-modal {
    width: 90% !important;
    margin: 20px auto !important;
  }
}

.member__modal__profile--details {
  align-items: center;
  width: 280px;
}

.member__modal__profile--picture {
  width: 280px;
  height: 280px;
}

section.m-x-auto.member-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem auto auto auto;
}

.member-pagination {
  .ant-pagination-item-active {
    border-color: $color__yellow;
    a {
      color: $color__yellow;
    }
  }
}
