@use "../../styles/variables.scss" as *;

.details {
  padding-top: 9rem;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &__arrow-right-box {
    z-index: 1;
    margin-right: 2rem;
    position: absolute;
    right: 20px;
    top: 50%;
  }
  &__arrow-left-box {
    margin-left: 2rem;
    position: absolute;
    left: 10px;
    top: 50%;
  }
  h1 {
    line-height: 1.2em;
  }

  &__main-cont {
    height: 100%;
    header {
      //   margin-block: 2.4rem;
      h1 {
        font-size: clamp(3.1rem, 4.2vw, 6.9rem);
      }
    }

    main {
      margin-block: 3.2rem;
      p {
        font-size: $normal;
        font-weight: 300;
        margin-block: 2rem;
        line-height: 1.5em;
      }
    }

    footer {
    }

    &__content-box {
      @media (min-width: 40em) {
        width: 45vw;
      }
      margin-right: auto;
    }

    &__img-box {
      @media (max-width: 40em) {
        display: none;
      }
      position: absolute;
      width: 55vw;
      height: auto;
      bottom: -0.5%;
      right: 0%;
      div {
        width: 100%;
        height: 100%;
        img {
          height: auto;
        }
      }
    }
  }
}


@media screen and (max-width: 580px) and (min-width: 260px) {
  
  .details {
    padding-bottom: 4rem;
  }

}