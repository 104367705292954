.card-content {
    width: 100%;
    height: 100%;
    // overflow-y: auto;
    box-shadow: 0 0 18px rgba(#4a4a4a, 0.2);
    background: #f8f9fa;
    padding: 10px;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // height: calc(100vh - 60px);
    padding: 20px;
    box-sizing: border-box;
    padding-top: 7rem;
    overflow: hidden;
}

.ant-table {
    width: 100% !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    background: #fff !important;
    overflow: hidden !important;
    border: none !important;
    font-family: "Manrope", sans-serif; /* Apply the font to the table */
    // font-style: italic;

    /* Header Styling */
    .ant-table-thead {
        background-color: #fcb01a !important;
        color: #fff !important;

        th {
            font-size: 16px !important;
            font-weight: 600 !important;
            text-align: left !important;
            padding: 12px 16px !important;
            border-bottom: 2px solid #e67e22 !important;
            font-family: "Manrope", sans-serif;
        }
    }

    .ant-table-tbody {
        background-color: #f9f9f9 !important;

        tr {
            transition: background-color 0.3s ease !important;

            &:nth-child(even) {
                background-color: #ecf0f1 !important;
            }

            td {
                padding: 10px 15px !important;
                text-align: left !important;
                font-size: 13px !important;
                color: #333 !important;
                border-bottom: 1px solid #e0e0e0 !important;
            }
        }
    }

    /* Pagination Styling */
    .ant-pagination {
        background-color: #fff !important;
        text-align: center !important;
        border-top: 1px solid #e0e0e0 !important;
        padding: 16px 0 !important;

        .ant-pagination-item {
            background-color: #fcb01a !important;
            border-color: #fcb01a !important;
            color: white !important;
            font-weight: bold !important;
            padding: 8px 12px !important;
            transition: background-color 0.3s, border-color 0.3s !important;

            &:hover {
                background-color: #e67e22 !important;
                border-color: #e67e22 !important;
            }
        }

        .ant-pagination-item-active {
            background-color: #e67e22 !important;
            border-color: #e67e22 !important;
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            font-weight: 600 !important;
            color: #fcb01a !important;
        }

        .ant-pagination-disabled {
            color: #ccc !important;
            background-color: #f5f5f5 !important;
        }
    }

    @media (max-width: 767px) {

        .ant-table-thead th,
        .ant-table-cell {
            font-size: 14px !important;
            padding: 8px 12px !important;
        }

        .ant-table-tbody tr:hover {
            background-color: #f39c12 !important;
        }

        .ant-pagination {
            font-size: 12px !important;
            padding: 8px 0 !important;
        }
    }
}

.ant-table-wrapper .ant-table-content {
    scrollbar-color: #fcb01a #f1f1f1 !important;
    scrollbar-width: thin !important;  
}
