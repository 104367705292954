@use "../../styles/variables.scss" as *;
.blog {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: $extra__large;
    display: flex;
    line-height: 1.5em;
    align-items: center;
    gap: 0.9rem;
  }
}
