@use "../../styles/variables.scss" as *;

.card {
  border: 1px solid $color__yellow;
  border-radius: 0.6rem;
  background-color: $color__white;

  section {
    margin-block: 1rem;
  }
  &__heading-box {
    display: flex;
    margin-block: 0.5rem;
    padding-block: 1.5rem;
    align-items: center;
    border-bottom: 2px solid $color__black_shade_2_light;
    &__image-cont {
      border-radius: 50%;
      width: 6.6rem;
      height: 6.6rem;
      overflow: hidden;
    }

    &__desc-cont {
      margin-left: 1rem;

      label,
      blockquote {
        font-size: calc($normal - 0.1vw);
        margin-block: 1.1rem;
        color: $color__black_shade_2;
      }

      blockquote {
        font-weight: 300;
      }

      label {
        font-weight: 700;
      }
    }
  }

  &__icon-box {
    padding-left: 0.5rem;
    margin-top: 1rem;
    svg {
      width: calc($normal + 0.3vw);
    }
  }

  &__desc-box {
    margin-block: 1rem;
    p {
      font-size: calc($small - 0.2vw);
      line-height: 1.5em;
      color: $color__black_shade_2;
    }
  }
}
